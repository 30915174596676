import type { ISharedTextProps } from '~/components/SharedText/SharedText.types';
import { Colors } from '~/constants/colors.constants';
import { FontSizes, FontWeights } from '~/types/SharedFont.types';

export const titleStyle = {
  color: Colors.NEUTRAL.WHITE,
  fontCase: 'upper',
  fontFamily: 'var(--reaver)',
  letterSpacing: '0.1em',
  size: FontSizes.LARGE,
  weight: FontWeights.BOLD,
} as ISharedTextProps;

export const titleStyleDesktop = {
  ...titleStyle,
  size: FontSizes.LARGE_2XL,
};

export const colorStyle = {
  bgColor: 'var(--main-color-500)',
  inventoryBgColor: 'var(--main-color-600)',
  scrollColor: Colors.NEUTRAL.WHITE_15,
} as const;
